import { backgroundLayoutVariants, modalVariants } from "shared/animations";
import { EDimensions } from "shared/constants";
import { useAppSelector } from "shared/hooks/redux/useAppSelector";
import useWindowDimensions from "shared/hooks/useWindowDimensions";
import { EModals, selectModalState } from "store/slices/modals";

import styles from "./styles";

interface IModalWrapper {
  children: JSX.Element[];
  closeModal: () => void;
}

export interface IPropsModalWrapper {
  isCentered?: boolean;
  isFullWidth?: boolean;
  height?: string;
  isFixedBottom?: boolean;
}

export default function ModalWrapper({ children, closeModal }: IModalWrapper): JSX.Element {
  const { modal } = useAppSelector(selectModalState);
  const dimension = useWindowDimensions();

  const modalProps: IPropsModalWrapper = {};

  switch (modal) {
    case EModals.FILTER_MODAL:
    case EModals.SHARE_MODAL: {
      modalProps.isCentered = dimension !== EDimensions.SMALL;
      modalProps.isFullWidth = dimension === EDimensions.SMALL;
      modalProps.isFixedBottom = dimension === EDimensions.SMALL;
      break;
    }
    case EModals.SEARCH_MODAL: {
      modalProps.isCentered = dimension !== EDimensions.SMALL;
      modalProps.isFullWidth = dimension === EDimensions.SMALL;
      modalProps.height = dimension === EDimensions.SMALL ? "85vh" : "33.75rem";
      modalProps.isFixedBottom = dimension === EDimensions.SMALL;
      break;
    }
    case EModals.SIGN_OUT_MODAL: {
      modalProps.isCentered = true;
      break;
    }
    default:
      break;
  }

  return (
    <styles.BackgroundLayout
      onClick={closeModal}
      variants={backgroundLayoutVariants}
      initial="initial"
      animate="animate"
      exit="exit"
    >
      <styles.Modal
        onClick={(e) => e.stopPropagation()}
        exit="close"
        animate="open"
        initial="close"
        variants={modalVariants}
        height={modalProps.height}
        isCentered={modalProps.isCentered}
        isFullWidth={modalProps.isFullWidth}
        isFixedBottom={modalProps.isFixedBottom}
      >
        {children}
      </styles.Modal>
    </styles.BackgroundLayout>
  );
}
