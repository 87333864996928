import Big from "big.js";

import { ZERO } from "shared/constants";
import {
  IFilter,
  EFilterByStatus,
  EFilterByParticipation,
  EFilterByResult,
  ISale,
  IUserData,
  EStatus,
} from "shared/interfaces";

const sortByEndDateRevers = (a: ISale, b: ISale) => b.endDate - a.endDate;
const sortByEndDate = (a: ISale, b: ISale) => a.endDate - b.endDate;
const sortByStartDate = (a: ISale, b: ISale) => a.startDate - b.startDate;
const sortByRefundAmount = (a: ISale, b: ISale) =>
  a.userData && b.userData ? Number(b.userData.refundAvailable) - Number(a.userData.refundAvailable) : 0;
const sortByClaimAmount = (a: ISale, b: ISale) =>
  a.userData && b.userData ? Number(b.userData.claimAvailable) - Number(a.userData.claimAvailable) : 0;
const sortByPurchaseClaimRatio = (a: ISale, b: ISale) =>
  a.userData && b.userData
    ? Number(Big(a.userData.purchase).eq(a.userData.claimed)) - Number(Big(b.userData.purchase).eq(b.userData.claimed))
    : 0;

export const getFilteredSalesArray = (sales: ISale[]) => {
  const activeSales = sales.filter((sale) => sale.status === EStatus.OPEN);
  const pendingSales = sales.filter((sale) => sale.status === EStatus.SOON);
  const completedSales = sales.filter((sale) => [EStatus.CLOSED, EStatus.CLIFF, EStatus.VESTING].includes(sale.status));
  const cancelledSales = sales.filter((sale) => sale.status === EStatus.CANCELLED);

  const highPrioritySales = [...activeSales.sort(sortByEndDate)];
  const filteredSales = [
    ...pendingSales.sort(sortByStartDate),
    ...completedSales.sort(sortByEndDateRevers).sort(sortByPurchaseClaimRatio),
    ...cancelledSales.sort(sortByEndDateRevers),
  ]
    .sort(sortByRefundAmount)
    .sort(sortByClaimAmount);

  return highPrioritySales.concat(filteredSales);
};

export const getSalesByFilter = (sales: ISale[], filter: IFilter) => {
  const filteredSales = getFilteredSalesArray(sales);

  const { status, participation, result } = filter;
  const filters: { key: string; value: EFilterByStatus | EFilterByParticipation | EFilterByResult }[] = [];
  if (status !== EFilterByStatus.ALL) filters.push({ key: "status", value: status });
  if (participation !== EFilterByParticipation.ALL) {
    filters.push({ key: "participation", value: participation });
  }
  if (result !== EFilterByResult.ALL) {
    filters.push({ key: "result", value: result });
  }

  if (!filters.length) return filteredSales;

  return filteredSales.filter(
    (sale) =>
      !filters.some(({ key, value }) => {
        if (
          sale.filter.result === EFilterByResult.BOTH &&
          (value === EFilterByResult.CLAIM || value === EFilterByResult.REFUND)
        ) {
          return false;
        }
        return sale.filter[key as keyof typeof sale.filter] !== value;
      })
  );
};

export const statusOrderMap = {
  [EStatus.OPEN]: EFilterByStatus.OPEN,
  [EStatus.SOON]: EFilterByStatus.SOON,
  [EStatus.CLIFF]: EFilterByStatus.CLOSED,
  [EStatus.VESTING]: EFilterByStatus.CLOSED,
  [EStatus.CLOSED]: EFilterByStatus.CLOSED,
  [EStatus.CANCELLED]: EFilterByStatus.CLOSED,
  [EStatus.FUTURE]: EFilterByStatus.CLOSED,
};

export const getUserDataFilter = (
  userData: IUserData
): {
  filterByParticipation: EFilterByParticipation;
  filterByResult: EFilterByResult;
} => {
  let filterByParticipation: EFilterByParticipation = EFilterByParticipation.ALL;
  let filterByResult: EFilterByResult = EFilterByResult.ALL;
  if (userData.claimAvailable && userData.refundAvailable) {
    filterByResult = EFilterByResult.BOTH;
  } else if (userData.claimAvailable) {
    filterByResult = EFilterByResult.CLAIM;
  } else if (userData.refundAvailable) {
    filterByResult = EFilterByResult.REFUND;
  }
  if (userData && Big(userData.deposited).gt(ZERO)) filterByParticipation = EFilterByParticipation.JOINED;
  return {
    filterByParticipation,
    filterByResult,
  };
};
