import { motion } from "framer-motion";
import styled from "styled-components";

const BackgroundLayout = styled(motion.div)`
  top: 0;
  left: 0;
  width: 100%;
  z-index: 199;
  height: 100%;
  display: flex;
  overflow: auto;
  position: fixed;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.blackOp04};
`;

const Modal = styled(motion.div)<{
  isCentered?: boolean;
  isFullWidth?: boolean;
  height?: string;
  isFixedBottom?: boolean;
}>`
  z-index: 200;
  padding: 1rem;
  display: flex;
  overflow: auto;
  max-width: 100%;
  max-height: 80vh;
  position: relative;
  align-self: center;
  height: fit-content;
  border-radius: 1rem;
  flex-direction: column;
  color: ${({ theme }) => theme.black};
  background-color: ${({ theme }) => theme.white};
  box-shadow: 0px 4px 8px -4px ${({ theme }) => theme.boxShadowCard};
  min-width: ${({ isFullWidth }) => (isFullWidth ? "100%" : "20.5rem")};
  ${({ theme }) => theme.mediaWidth.upToSmall`padding: 1.5rem 1rem;`}
  ${({ theme, isFixedBottom }) => theme.mediaWidth.upToSmall`
    align-self: ${isFixedBottom ? "flex-end" : "unset"};
    border-radius: ${isFixedBottom ? "1rem 1rem 0 0" : "1rem"};
  `}
`;

export default {
  BackgroundLayout,
  Modal,
};
