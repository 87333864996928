import { Fragment, useCallback, useState } from "react";
import { v4 as uuid } from "uuid";

import Media from "shared/components/Media";
import ModalWrapper from "shared/components/Modals/ModalWrapper";
import Status from "shared/components/Status";
import Translate from "shared/components/Translate";
import { EMPTY_STRING } from "shared/constants";
import { useAppSelector } from "shared/hooks/redux/useAppSelector";
import { EStatus, IFutureSale, ISale } from "shared/interfaces";
import { ESalesLogo } from "shared/interfaces";
import { getToken } from "store/actions/helpers";
import { selectData } from "store/selectors/selectData";

import styles from "./styles";
import { filterSalesBySearch } from "./utils";

export interface ISearchModal {
  closeModal: () => void;
  handleConfirm: (saleId: number, status: EStatus) => void;
}

const highlightText = (searchValue: string, name: string) => {
  if (!searchValue) return name;
  const regexp = new RegExp(searchValue, "ig");
  const matchValue = name.match(regexp);
  if (matchValue) {
    return name.split(regexp).map((s, index, array) => {
      if (index < array.length - 1) {
        const c = matchValue.shift();
        return (
          <Fragment key={`${index}-${uuid()}`}>
            {s}
            <span>{c}</span>
          </Fragment>
        );
      }
      return <Fragment key={`${index}-${uuid()}`}>{s}</Fragment>;
    });
  }
  return name;
};

export default function SearchModal({ closeModal, handleConfirm }: ISearchModal) {
  const { tokens, sales } = useAppSelector(selectData);
  const [searchValue, setSearchValue] = useState<string>("");
  const [filteredSales, setFilteredSales] = useState<ISale[]>(sales.arr);
  const [filteredFutureSales, setFilteredFutureSales] = useState<IFutureSale[]>(sales.future.arr);
  const onChange = useCallback(
    (search: string) => {
      setSearchValue(search);
      const searchLowerCase = search.toLowerCase();
      const isSearchEmpty = search === EMPTY_STRING;

      const salesBySearch = !isSearchEmpty ? filterSalesBySearch(sales.arr, searchLowerCase) : sales.arr;
      const futureSalesBySearch = !isSearchEmpty
        ? filterSalesBySearch(sales.future.arr, searchLowerCase)
        : sales.future.arr;

      setFilteredFutureSales(futureSalesBySearch);
      setFilteredSales(salesBySearch);
    },
    [sales.arr, sales.future.arr]
  );
  const currentSales = [...filteredFutureSales, ...filteredSales];
  return (
    <ModalWrapper closeModal={closeModal}>
      <styles.Header>
        <styles.StyledInput>
          <styles.Search />
          <styles.Input value={searchValue} onChange={(value) => onChange(value.target.value)} />
          {searchValue && <styles.ClearSearch onClick={() => onChange("")} />}
        </styles.StyledInput>
        <styles.Close onClick={closeModal} />
      </styles.Header>
      <styles.Body>
        {!currentSales.length && (
          <styles.NoResultsWrapper>
            <styles.NoResultsIcon />
            <styles.NoResultsTitle>
              <Translate value="NoResults.Title" interpolation={{ searchValue }} />
            </styles.NoResultsTitle>
            <styles.NoResultsLabel>
              <Translate value="NoResults.Label" />
            </styles.NoResultsLabel>
          </styles.NoResultsWrapper>
        )}
        {currentSales.map((sale) => {
          const token = getToken(sale.depositTokenId, tokens);
          return (
            <styles.Row
              key={`${sale.id}-${uuid()}`}
              onClick={() => {
                closeModal();
                handleConfirm(sale.id, sale.status);
              }}
            >
              <styles.ImageContainer>
                <Media saleId={sale.id} typeLogo={ESalesLogo.LOGO} />
              </styles.ImageContainer>
              <styles.WrapperDescription>
                <styles.Title>{highlightText(searchValue, sale.metadata.name)}</styles.Title>
                <styles.Label>
                  <Translate value="Sale.Stage" interpolation={{ currency: token?.metadata?.symbol }} />
                </styles.Label>
              </styles.WrapperDescription>
              <Status type={sale.status} />
            </styles.Row>
          );
        })}
      </styles.Body>
    </ModalWrapper>
  );
}
