import cloneDeep from "lodash/cloneDeep";

export const slideVerticalAnimation = {
  open: {
    rotateX: 0,
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.3,
      mass: 0.8,
      type: "spring",
    },
    display: "block",
  },
  close: {
    rotateX: -15,
    y: -100,
    opacity: 0,
    transition: {
      duration: 0.3,
    },
    transitionEnd: {
      display: "none",
    },
  },
};

export const slideBurgerMenuAnimation = cloneDeep(slideVerticalAnimation);
slideBurgerMenuAnimation.open.display = "flex";

export const listSlideUpOpacityAnimation = {
  visible: (i: number) => ({
    opacity: 1,
    y: 0,
    transition: {
      delay: i * 0.1,
    },
  }),
  hidden: { opacity: 0, y: 50 },
};

export const modalVariants = {
  initial: {
    y: "-20px",
    opacity: 0,
  },
  open: {
    y: "0",
    opacity: 1,
    transition: {
      duration: 0.3,
      type: "spring",
      damping: 25,
      stiffness: 500,
    },
  },
  close: {
    y: "20px",
    opacity: 0,
    transition: {
      duration: 0.2,
    },
  },
};

export const backgroundLayoutVariants = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
};
