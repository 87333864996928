import { motion } from "framer-motion";
import styled from "styled-components";

import { hoverTransition } from "shared/theme/transitions";

const Container = styled(motion.div)`
  top: 0;
  left: 0;
  z-index: "10";
  display: "flex";
  min-width: 100vw;
  min-height: 100vh;
  position: absolute;
  padding-left: 2rem;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 3.188rem;
  justify-content: flex-end;
  background-color: ${({ theme }) => theme.black};

  & > div {
    &:last-child {
      margin-top: 5.25rem;
    }
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const Row = styled.a`
  display: flex;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 600;
  align-items: center;
  line-height: 1.188rem;
  text-decoration: none;
  margin-bottom: 2.375rem;
  color: ${({ theme }) => theme.white};
  transition: ${hoverTransition};

  &:hover {
    opacity: 0.8;
  }

  & > svg {
    margin-right: 0.875rem;
  }
`;

const Line = styled.div`
  opacity: 0.4;
  width: 19.5rem;
  height: 0.063rem;
  margin-bottom: 2.375rem;
  background: ${({ theme }) => theme.grayLine};
`;

export default {
  Container,
  Column,
  Row,
  Line,
};
