/* eslint-disable @typescript-eslint/no-explicit-any */
import { css, DefaultTheme } from "styled-components/macro";

import { MEDIA_WIDTHS } from "shared/constants";

const mediaWidthTemplates: {
  [width in keyof typeof MEDIA_WIDTHS]: typeof css;
} = Object.keys(MEDIA_WIDTHS).reduce((accumulator, size) => {
  (accumulator as any)[size] = (a: any, b: any, c: any) => css`
    @media (max-width: ${(MEDIA_WIDTHS as any)[size]}px) {
      ${css(a, b, c)}
    }
  `;
  return accumulator;
}, {}) as any;

export const colors = {
  black: "#131313",
  blackOp04: "rgba(19, 19, 19, 0.4)",
  blackOp015: "rgba(0, 0, 0, 0.15)",
  white: "#FFFFFF",
  orange: "#FF5924",
  orangeRGB: "255, 89, 36",
  grey: "#B5BBC2",
  greyDark: "#dbdcde",
  primaryGrey: "#8E939A",
  bgGrey: "181,187,194",
  lightGrey: "#676C73",
  darkGrey: "#B3B3B3",
  grayOp04: "#D2D4D7",
  grayOp01: "#F4F4F5",

  red: "#F65151",
  redDot: "#F86069",
  shadowWrapper: "rgba(19, 19, 19, 0.2)",
  boxShadowInput: "rgba(255, 89, 36, 0.1)",
  opacityGrey: "rgba(181, 187, 194, 0.2)",
  opacityGreyDark: "rgba(181, 187, 194, 0.45)",
  transparent: "transparent",

  statusOpenBg: "#DCEED1",
  statusSoonBg: "#FDE9CC",
  statusEndedBg: "#E8E9EB",
  statusCanceledBg: "#FDDCDC",
  statusOpenText: "#51A81B",
  statusSoonText: "#F49302",
  statusEndedText: "#8E939A",
  statusCanceledText: "#F65151",

  orangeHover: "#F54F1A",
  orangeActive: "#EB4510",
  lightOrange: "#FFEEE9",
  lightOrangeHover: "#FFDED3",
  lightOrangeActive: "#FFCDBD",

  orangeTextHighlight: "#FFCDBD",
  statusOpenCard: "#51A81B",
  orangeDivision: "#FFD0C1",
  blue: "#3BBAFF",
  blueOp01: "#EBF8FF",
  blueHover: "#45C4FF",
  blueActive: "#4FCEFF",
  landingLinearGradient: "linear-gradient(90deg, #3bbaff 1.98%, #1ec659 143.19%)",
};

export const logStyles = `
  color: ${colors.red};
  font-weight: 600;
`;

function theme(): DefaultTheme {
  return {
    ...colors,

    grids: {
      sm: 8,
      md: 12,
      lg: 24,
    },

    // media queries
    mediaWidth: mediaWidthTemplates,
  };
}

export default theme;
